import { rest } from 'msw';

export const createMockAccountResponseHandler = (responseData) => {
    return rest.get(
        'http://localhost:8200/api/account_details',
        (req, res, ctx) => {
            return res(ctx.json(responseData));
        }
    );
};

export const createMockUsersResponseHandler = (responseData) => {
    return rest.get('http://localhost:8200/api/users', (req, res, ctx) => {
        return res(ctx.json(responseData));
    });
};

export const createMockFilesV1ResponseHandler = (
    courseCatalog,
    filesResponse
) => {
    rest.post('http://localhost:8200/api/files', (req, res, ctx) => {
        if (req.url.searchParams.get('course') === courseCatalog) {
            return res(ctx.json(filesResponse));
        }

        return res(ctx.json([]));
    });
};

// export const createMockFilesV2ResponseHandler = (filesResponse) => {
//     rest.post('http://localhost:8200/api/files', async (req, res, ctx) => {
//         const reqPayload = await req.json();

//         return res(ctx.json(filesResponse));
//     });
// };
