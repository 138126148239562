import React, { useState, useEffect, StrictMode } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import {
    extendRange,
    instantiateRange,
    isRangeProvisioned,
    calculateDisplayInterval,
    deleteRange,
} from '../../utils/range-provisioning';
import CaptionLabel from './captionLabel';
import StatusLabel from './statusLabel';
import RegionSelector from './regionSelector';
import Button from './button';

import './component.css';
import { simpleAlert } from 'react-simple-dialogs';

/**
 * Renders a component for managing a dynamically-provisioned range.
 *
 * @param {Object} props React props passed into the component.
 * @param {import ('../../../../types/types').RangeRule} props.rule - Data about the course range.
 * @param {import ('../../../../types/types').RangeInstance | null} props.activeRange - The active
 *  range for this button, if applicable.
 * @param {import ('../../../../types/types').RangeInstance[]} props.ranges - An array of ranges.
 * @returns {JSX.Element}
 */
const Component = ({
    rule,
    activeRange,
    ranges,
}) => {
    const defaultRegion = rule.aws_regions.length > 0 ? rule.aws_regions[0] : '';

    const [range, setRange] = useState(activeRange);
    const [allRanges, setAllRanges] = useState(ranges);
    const [buttonCaption, setButtonCaption] = useState('');
    const [awsRegion, setAwsRegion] = useState(defaultRegion);

    const [workflowState, setWorkflowState] = useState('unprovisioned');
    const [provisioningComplete, setProvisioningComplete] = useState(false);

    /** @type {string | number | NodeJS.Timeout | undefined} */
    let intervalID;
    const interval = 15; // How often should we poll and update the UI?

    const handleRangeFulfillment = () => {
        if (range?.status === 'pending' && !intervalID) {
            let elapsedSeconds = 0;

            intervalID = setInterval(async () => {
                const qualifiedRange = {
                    product_catalog: rule.mylabs_part.toUpperCase(),
                    id: range.id,
                };

                elapsedSeconds = Math.floor(moment().diff(moment(range.create_at), 'seconds') / interval);
                setButtonCaption(
                    `Provisioning range (${calculateDisplayInterval(elapsedSeconds * interval)} elapsed)...`
                );

                // Poll...
                if (await isRangeProvisioned(qualifiedRange)) {
                    clearInterval(intervalID);
                    intervalID = undefined;

                    /** @type {string | number | NodeJS.Timeout | undefined} */
                    let countdownIntervalID = setInterval(() => {
                        elapsedSeconds = Math.floor(moment().diff(moment(range.create_at), 'seconds') / interval);
                        setButtonCaption(
                            `Provisioning range (${calculateDisplayInterval(elapsedSeconds * interval)} elapsed)...
                        `);

                        if ((elapsedSeconds / (60 / interval)) > rule.provisioning_duration_in_minutes) {
                            clearInterval(countdownIntervalID);
                            countdownIntervalID = undefined;

                            setButtonCaption(
                                'Your range has been provisioned. Please refresh your page for access instructions.');
                            setProvisioningComplete(true);
                        }
                    }, interval * 1000);
                }
            }, interval * 1000);
        } else if (range?.status === 'fulfilled') {
            // TODO: Implement means to reload files and credentials for lab.
        }
    };

    const handleProvisionRange = async () => {
        setWorkflowState('provisioning');

        const provisionedRange = await instantiateRange(
            rule.mylabs_part,
            rule.range_type,
            awsRegion,
        );

        setRange(provisionedRange);
        setAllRanges([
            ...allRanges,
            provisionedRange,
        ]);

        return provisionedRange;
    };

    const handleExtendRange = async () => {
        const updatedRange = await extendRange(range.id, range.expires_at);

        if (typeof updatedRange === 'undefined' || updatedRange === null) {
            const expired = structuredClone(range);
            const expirationDate = new Date();

            expirationDate.setTime(new Date().getTime() - 1000);

            expired.expires_at = expirationDate.toISOString();
            expired.status = 'completed';

            setRange(null);
            setAllRanges([
                ...allRanges.filter((r) => r.id !== range.id),
                expired,
            ]);

            await simpleAlert(
                'The range has already expired and cannot be extended. Please provision a new range.'
            );
        } else {
            setRange(updatedRange);
        }

        return updatedRange;
    };

    const determineInitialState = () => {
        if (typeof range !== 'undefined' && range !== null) {
            if (range.id > 0 && range.status === 'pending') {
                setWorkflowState('provisioning');
            }
            if (range.expires_at > range.create_at) {
                setWorkflowState('provisioned');
            }
        }
    };

    /**
     *
     * @returns RangeInstance
     */
    const handleClick = async () => {
        if (workflowState === 'unprovisioned') {
            return await handleProvisionRange();
        }
        if (workflowState === 'provisioned') {
            return await handleExtendRange();
        }

        return false;
    };

    const handleDeleteClick = async () => {
        if (!range) {
            return;
        }

        const expired = new Date() > new Date(range.expires_at);
        const deletedRange = await deleteRange(range.id);

        if (deletedRange || expired) {
            setWorkflowState('unprovisioned');
            const deleted = structuredClone(range);

            deleted.status = 'completed';
            deleted.expires_at = new Date().toISOString();
            deleted.deleted_at = new Date().toISOString();

            setRange(null);
            setAllRanges([
                ...allRanges.filter((r) => r.id !== range.id),
                deleted,
            ]);
        } else {
            simpleAlert('There was an error deleting the range. Please try again.');
        }
    };

    useEffect(() => {
        determineInitialState();

        handleRangeFulfillment();

        return () => {};
    }, [range, allRanges]);

    const nonProvisioningRanges = allRanges.filter((r) => r.status !== 'pending');
    const mostRecentExpiredRange = [...allRanges].reverse()[0];

    return (
        <StrictMode>
            <div className="dynamic-range-provisioning">
                <StatusLabel range={ range || mostRecentExpiredRange } handleDeleteClick={ handleDeleteClick } />

                {workflowState === 'unprovisioned' &&
                    rule.aws_regions.length > 0 && <RegionSelector
                    rule={ rule }
                    defaultRegion=""
                    handleChange={ (region) => setAwsRegion(region) }
                />}

                <div className="button-with-caption">
                    <Button
                        range={ range }
                        rule={ rule }
                        ranges={ nonProvisioningRanges }
                        caption={ buttonCaption }
                        handleClick={ handleClick }
                    />
                    <CaptionLabel
                        range={ range }
                        rule={ rule }
                        ranges={ allRanges }
                        provisioned={ provisioningComplete }
                    />
                </div>
            </div>
        </StrictMode>
    );
};

Component.propTypes = {
    /** The range provisioning definition. */
    rule: PropTypes.object,

    /**
     * The object that will hold the eventual range provisioning request. This range may be empty (`null`)
     * or may have contain an object with `status` property.
     */
    activeRange: PropTypes.object,

    /** An array of ranges. */
    ranges: PropTypes.arrayOf(PropTypes.object),
};

export default Component;
