import { setupStore } from '../../../../app/store';
import LabDisplay from './lab-display';
import { Provider } from 'react-redux';
import React from 'react';
import { TestConfigState } from '../../../../mocks/redux/data';
import { TestLab, formatInstructionsAsRaw } from '../../../../mocks/labs/data';
import { OpenFeatureProvider } from '@openfeature/react-sdk';
import { featureFlagDomain } from '../../../../config/features/flags';
import { testUserResponse, testAccountResponse } from '../../../../mocks/myLabsTestData';

import type { Meta, StoryObj } from '@storybook/react';
import { rest } from 'msw';
import appConfig from '../../../../config/appConfig';
export const MockedState = {
    accountReducer: testAccountResponse.data,
    configReducer: {
        config: TestConfigState(),
    },
    labReducer: {
        labResults: testUserResponse.data,
        isLoading: false,
        error: null,
    },
    filesReducer: {
        files: {},
    },
    rangeProvisioningReducer: {
        courses: testUserResponse.rangeProvisioning.courses.data,
        ranges: testUserResponse.rangeProvisioning.ranges.data,
    },
};

/**
 * @deprecated - This should be removed when all references to this are removed from other modules.
 * All lab data should come from testUserResponse.data
 */
export const mockedLabs = {
    singleGeneric: [formatInstructionsAsRaw(TestLab({
        id: 1,
        productId: 1,
    }))],
    onDemandGeneric: [
        formatInstructionsAsRaw(TestLab({ course: 'CSE101', id: 2, productId: 2, nameSectionSuffix: 'A' })),
        formatInstructionsAsRaw(TestLab({
            course: 'CSE101',
            id: 3,
            productId: 2,
            nameSectionSuffix: 'B',
            instructions: {},
            additionalInstructions: {
                Overview: [
                    {
                        field_lab_instruction_display_na:
                            'Test Lab B Overview',
                        field_lab_instruction: '<p>This is a test lab</p>',
                    },
                ],
            },
        })),
    ],
    liveGeneric: [
        formatInstructionsAsRaw(TestLab({
            course: 'CSE102',
            id: 4,
            productId: 3,
            eventProductId: 123,
            nameSectionSuffix: 'A',
            eventName: 'Automated Live Test Lab',
            modality: 'LIVE',
        })),
        formatInstructionsAsRaw(TestLab({
            course: 'CSE102',
            id: 5,
            productId: 3,
            eventProductId: 123,
            section: '6',
            nameSectionSuffix: 'B',
            eventName: 'Automated Live Test Lab',
            modality: 'LIVE',
        })),
    ],
};


const meta: Meta<typeof LabDisplay> = {
    title: 'Layout/Lab Display',
    component: LabDisplay,
    decorators: [(Story) =>
        <OpenFeatureProvider domain={ featureFlagDomain }>
            {/* @ts-ignore */}
            <Provider store={ setupStore(MockedState) } >
                <Story />
            </Provider>
        </OpenFeatureProvider>,
    ],
    parameters: {
        msw: {
            handlers: [
                rest.get('https://www.sans.org/services/pywars/accountcreate', (req, res, ctx) => {
                    return res(
                        ctx.json({password: 'pywarspassword'})
                    );
                }),
                rest.get('https://www.sans.org/services/pywars/passwordreset', (req, res, ctx) => {
                    return res(
                        ctx.json({password: 'newpassword'})
                    );
                }),
                rest.get(appConfig().services.skillable.baseUrl, (req, res, ctx) => {
                    return res(
                        ctx.json({url: 'https://test.example.com'})
                    );
                }),
                
            ],
        },
    },
    excludeStories: ['MockedState', 'mockedLabs'],
};

export default meta;
type Story = StoryObj<typeof LabDisplay>;

export const Default: Story = {
    args: {
        labResults: testUserResponse.data,
    },
    parameters: {
        msw: {
            handlers: [
                // dynamic ovpn mock
                // eslint-disable-next-line
                rest.get(`${appConfig().services.mylabsService.baseUrl}/ovpn/:labId/:userProductCacheId?os=foo`, (req, res, ctx) => {
                    const file = new Blob([`foo ${req.params.labId}`], { type: 'text/plain' });
                    return res(
                        ctx.set('Content-Length', file.size.toString()),
                        ctx.set('Content-Type', 'text/plain'),
                        ctx.json({filename: 'foo.ovpn', file}),
                    );
                }),
            ],
        },
    },
};

/**
 * All requests in this lab are set to return invalid responses
 */
export const WithErrors: Story = {
    args: {
        labResults: testUserResponse.data,
    },
    parameters: {
        msw: {
            handlers: [
                rest.get('https://www.sans.org/services/pywars/accountcreate', (req, res, ctx) => {
                    return res(
                        ctx.json({error_Status: 2})
                    );
                }),
                rest.get('https://www.sans.org/services/pywars/passwordreset', (req, res, ctx) => {
                    return res(
                        ctx.json({error: 'pywarspassword'})
                    );
                }),
                rest.get(appConfig().services.skillable.baseUrl, (req, res, ctx) => {
                    return res(
                        ctx.json({foo: 'https://test.example.com'})
                    );
                }),
                 
            ],
        },
    },
};
export const NoLabs: Story = {
    args: {
        labResults: [],
    },
};
