import './index.scss';
import { OpenFeatureProvider } from '@openfeature/react-sdk';

import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { setupStore } from './app/store';
import App from './views/app/app';
import AppConfiguration, { RuntimeEnvironment } from './config/appConfig';
import { featureFlagDomain, setupFeatureFlags } from './config/features/flags';
import FLAGS from './config/features/flags/';
const config = AppConfiguration();

const container = document.getElementById('root')!;
const root = createRoot(container);

export const store = setupStore({
    configReducer: {
        config,
    },
});


// Allow patching in local, development, and QA environments
if (
    (
        [
            'local',
            'legacy-development',
            'org7-development',
            'org7-qa',
            'org7-uat',
        ] as RuntimeEnvironment[]
    ).includes(config.runtimeEnv)
) {
    const mswToggle = window.localStorage.getItem('msw');

    if (mswToggle === '1') {
        const {worker} = require('./mocks/browser');

        worker.start();
    }
}

setupFeatureFlags(FLAGS);
root.render(
    <OpenFeatureProvider domain={ featureFlagDomain }>
        <Provider store={ store }>
            <App />
        </Provider>
    </OpenFeatureProvider>
);

registerServiceWorker();
