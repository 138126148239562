import React from 'react';
import App from '../../views/app/app';
import { Provider } from 'react-redux';
import { setupStore } from '../../app/store';
import { TestConfigState } from '../../mocks/redux/data';
import { formatInstructionsAsRaw } from '../../mocks/labs/data';
import { rest } from 'msw';
import { mockedLabs } from '../../submodules/shared/components/lab-display/lab-display.stories';
import { TestFileMetadata } from '../../mocks/files/data';
import AppConfiguration from '../../config/appConfig';
import { OpenFeatureProvider } from '@openfeature/react-sdk';
import prepareStoryFeatureFlags from '../../stories/prepareStoryFeatureFlags.ts';

const {
    client,
    flagDefaults,
} = await prepareStoryFeatureFlags();


// First, let's set up mocked API responses
export const MockAccountResponseData = {
    data: {
        sansEmail: 'test@sans.org',
        sansAccountID: '12345678',
        name: 'Test User',
    },
    result: 'success',
};

const mockedGenericLab = formatInstructionsAsRaw(mockedLabs.singleGeneric[0]);

export const MockUsersResponseData = {
    data: [mockedGenericLab],
    rangeProvisioning: {
        courses: {
            data: [],
            meta: {},
        },
        ranges: {
            data: [],
            meta: {},
        },
    },
    result: 'success',
};

// Set our initial mocked state with config only
// Redux will handle the rest based on the above data
export const MockedState = {
    configReducer: {
        config: TestConfigState(),
    },
};

export default {
    title: 'Organisms/App',
    component: App,
    decorators: [
        (Story) => (
            <OpenFeatureProvider client={ client }>
                <Provider store={ setupStore(MockedState) }>
                    <Story />
                </Provider>
            </OpenFeatureProvider>
        ),
    ],
    excludeStories: [
        'MockedState',
        'MockAccountResponseData',
        'MockUsersResponseData',
    ],
    parameters: {
        controls: { exclude: [] },
    },
};

export const Default = {
    args: {
        featureFlags: {
            ...flagDefaults,
        },
    },
    parameters: {
        msw: {
            handlers: [
                rest.get(
                    'http://localhost:8200/api/account_details',
                    (req, res, ctx) => {
                        return res(ctx.json(MockAccountResponseData));
                    }
                ),
                rest.get('http://localhost:8200/api/users', (req, res, ctx) => {
                    return res(ctx.json(MockUsersResponseData));
                }),
                rest.post(
                    'http://localhost:8200/api/files',
                    async (req, res, ctx) => {
                        return res(ctx.json([]));
                    }
                ),
            ],
        },
    },
};

const genericMockFiles = [TestFileMetadata(mockedGenericLab)];

export const WithFiles = {
    parameters: {
        msw: {
            handlers: [
                rest.get(
                    'http://localhost:8200/api/account_details',
                    (req, res, ctx) => {
                        return res(ctx.json(MockAccountResponseData));
                    }
                ),
                rest.get('http://localhost:8200/api/users', (req, res, ctx) => {
                    return res(ctx.json(MockUsersResponseData));
                }),
                rest.post(
                    'http://localhost:8200/api/files',
                    async (req, res, ctx) => {
                        const config = AppConfiguration();
                        // TODO: Need to determine how to handle feature flags in the storybook
                        const version = 'v2';

                        const rd = {};

                        if (version === 1) {
                            rd[mockedGenericLab.name] = genericMockFiles;
                        } else if (version === 2) {
                            const payload = await req.json();
                            const { eventProductID, instance, labID, labName } = payload;

                            rd[mockedGenericLab.testExtras.courseCatalog] = {
                                [mockedGenericLab.name]: {
                                    labName,
                                    eventProductID,
                                    labID,
                                    instance,
                                    metadata: genericMockFiles,
                                },
                            };
                        }
                        return res(ctx.json(rd));
                    }
                ),
            ],
        },
    },
};
