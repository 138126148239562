import React from 'react';
import App from '../../views/app/app';
import { Provider } from 'react-redux';
import { setupStore } from '../../app/store';
import { TestLab, formatInstructionsAsRaw } from '../../mocks/labs/data';
import { rest } from 'msw';
import { TestFileMetadata } from '../../mocks/files/data';
import {
    MockedState,
    MockAccountResponseData as accountResponseData,
} from '../../views/app/app.stories';
import {
    createMockAccountResponseHandler,
    createMockFilesV1ResponseHandler,
    createMockUsersResponseHandler,
} from '../../mocks/helpers';
import { generatedLabMocks } from '../../mocks/labs/courses/generateLabMocks';

const { sec599: sec599Mocks } = generatedLabMocks;
const usersResponseDataBase = {
    rangeProvisioning: {
        courses: {
            data: [
                {
                    id: 490,
                    product_version: 'sec599-j01-01',
                    mylabs_part: 'sec599a-j01-gen',
                    account_id: null,
                    range_type: 'labs',
                    provisioning_duration_in_minutes: 60,
                    default_duration_in_days: 5,
                    maximum_duration_in_days: 10,
                    maximum_runs: 10,
                    attributes: null,
                    created_at: '2024-01-03T09:10:00+00:00',
                    updated_at: '2024-01-03T09:10:00+00:00',
                    extension_duration_in_days: 5,
                },
                {
                    id: 495,
                    product_version: 'sec599-j01-01',
                    mylabs_part: 'sec599b-j01-gen',
                    account_id: null,
                    range_type: 'ctf',
                    provisioning_duration_in_minutes: 60,
                    default_duration_in_days: 5,
                    maximum_duration_in_days: 10,
                    maximum_runs: 10,
                    attributes: null,
                    created_at: '2024-01-03T09:10:00+00:00',
                    updated_at: '2024-01-03T09:10:00+00:00',
                    extension_duration_in_days: 5,
                },
            ],
        },
        ranges: {
            data: [],
        },
    },
    result: 'success',
};

const usersResponseDataLive = {
    ...usersResponseDataBase,
    data: [formatInstructionsAsRaw(sec599Mocks.live[79170].a)],
};
const usersResponseDataOnDemand = {
    ...usersResponseDataBase,
    data: [
        formatInstructionsAsRaw(sec599Mocks.onDemand.a),
        formatInstructionsAsRaw(sec599Mocks.onDemand.b),
    ],
};

export default {
    title: 'Mocks/Courses/SEC599',
    component: App,
    decorators: [
        (Story) => (
            <Provider store={ setupStore(MockedState) }>
                <Story />
            </Provider>
        ),
    ],
    excludeStories: [
        'MockedState',
        'sec599V1FilesResponse',
        'sec599V2FilesResponse',
    ],
    parameters: {
        controls: { exclude: [] },
    },
};

export const sec599V1FilesResponse = {
    [sec599Mocks.live[79170].a.event_product_id]: [
        TestFileMetadata(sec599Mocks.live[79170].a, {
            fileName: 'student-range',
            contentType: 'ovpn',
        }),
        TestFileMetadata(sec599Mocks.live[79170].a, {
            fileName: 'student-ssh-key',
            contentType: 'pem',
        }),
    ],
};

export const sec599V2FilesResponse = (payload) => {
    const { eventProductID, instance, labID, labName } = payload;
    const { name: instanceA } = sec599Mocks.live[79170].a;

    return {
        SEC599: {
            [instanceA]: {
                labName,
                eventProductID,
                labID,
                instance,
                metadata: [
                    TestFileMetadata(sec599Mocks.live[79170].a, {
                        fileName: 'student-range',
                        contentType: 'ovpn',
                    }),
                    TestFileMetadata(sec599Mocks.live[79170].a, {
                        fileName: 'student-ssh-key',
                        contentType: 'pem',
                    }),
                ],
            },
        },
    };
};

// Set MSW handlers for the mock
export const FilesApiV1Live = {
    parameters: {
        msw: {
            handlers: [
                createMockAccountResponseHandler(accountResponseData),
                createMockUsersResponseHandler(usersResponseDataLive),
                createMockFilesV1ResponseHandler(
                    'SEC599',
                    [TestFileMetadata(sec599Mocks.live[79170].a, {
                        fileName: 'student-range',
                        contentType: 'ovpn',
                    })]
                ),
            ],
        },
    },
};
export const FilesApiV1OnDemand = {
    parameters: {
        msw: {
            handlers: [
                createMockAccountResponseHandler(accountResponseData),
                createMockUsersResponseHandler(usersResponseDataOnDemand),
                createMockFilesV1ResponseHandler(
                    'SEC599',
                    sec599V1FilesResponse
                ),
            ],
        },
    },
};

export const FilesApiV2Live = {
    decorators: [
        (Story) => (
            <Provider
                store={ setupStore({
                    configReducer: {
                        config: {
                            ...MockedState.configReducer.config,
                            features: {
                                filesApiProxy: {
                                    getStudentFiles: {
                                        version: 2,
                                    },
                                },
                            },
                        },
                    },
                }) }
            >
                <Story />
            </Provider>
        ),
    ],
    parameters: {
        msw: {
            handlers: [
                createMockAccountResponseHandler(accountResponseData),
                createMockUsersResponseHandler(usersResponseDataLive),
                rest.post(
                    'http://localhost:8200/api/files',
                    async (req, res, ctx) => {
                        const reqPayload = await req.json();

                        return res(
                            ctx.json(
                                sec599V2FilesResponse(
                                    reqPayload[
                                        sec599Mocks.live[79170].a.testExtras
                                            .courseCatalog
                                    ][0]
                                )
                            )
                        );
                    }
                ),
            ],
        },
    },
};

export const FilesApiV2OnDemand = {
    decorators: [
        (Story) => (
            <Provider
                store={ setupStore({
                    configReducer: {
                        config: {
                            ...MockedState.configReducer.config,
                            features: {
                                filesApiProxy: {
                                    getStudentFiles: {
                                        version: 2,
                                    },
                                },
                            },
                        },
                    },
                }) }
            >
                <Story />
            </Provider>
        ),
    ],
    parameters: {
        msw: {
            handlers: [
                createMockAccountResponseHandler(accountResponseData),
                createMockUsersResponseHandler(usersResponseDataOnDemand),
                rest.post(
                    'http://localhost:8200/api/files',
                    async (req, res, ctx) => {
                        const reqPayload = await req.json();

                        return res(
                            ctx.json(
                                sec599V2FilesResponse(
                                    reqPayload[
                                        sec599Mocks.live[79170].a.testExtras
                                            .courseCatalog
                                    ][0]
                                )
                            )
                        );
                    }
                ),
            ],
        },
    },
};
