import './lab-display.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStringFlagValue } from '@openfeature/react-sdk';

import { getOVPNCert } from '../../utils/api-shared';
import { textToFile } from '../../utils/text-to-file';
import { dateBeforeNow } from '../../utils/date-before-now';
import { remapCourses } from '../../utils/remap-courses';
import Expandable from '../expandable';
import ContactUs from '../contact-us';
import Downloading from '../downloading';
import LicenseExpandable from './license-dropdown';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';

import * as R from 'ramda';
import { licensingExclude } from '../licensing/licenseMap';
import { NoLabInstructions } from './no-lab-instructions';
import { sortCourses } from '../../utils/course';
import { compareODAndLiveLab, extractLabData, sortLabs } from '../../utils/labs';
import { default as RangeInstantiationComponent } from '../range-provisioning/component';
import { renderRangeInstantiationButtonInputs } from '../../utils/range-provisioning';

import { mockedCourses } from '../../../../mocks/fakelab';
import FeatureHide from '../feature-hide/feature-hide';
import { useSelector } from 'react-redux';
import { renderDynamicContentV1, renderDynamicContentV2 } from './displayUtilities/renderDynamicContent';
import AppConfiguration from '../../../../config/appConfig';

const appConfig = AppConfiguration();

/* eslint-disable max-len, valid-jsdoc, no-undefined */

const hasInstructions = (labInstructions) => {
    return Object.keys(labInstructions).length !== 0;
};

/**
 * 
 * @param {Object} props
 * @param {import('../../../../types/types').LabProduct[]} props.labResults
 */
const LabDisplay = ({
    labResults,
}) => {
    const filesApiFeatureFlag = useStringFlagValue(
        'files-api-version',
        'v2',
    );
    const accountReducer = useSelector((state) => state.accountReducer);
    const rangeProvisioningReducer = useSelector((state) => state.rangeProvisioningReducer);
    const filesReducer = useSelector((state) => state.filesReducer);
    const studentFiles = filesReducer.files;
    const sansAccountID = accountReducer.sansAccountID;
    const sansEmail = accountReducer.sansEmail;
    const nname = accountReducer.name;
    const [showAlert, setShowAlert] = useState(false);
    const [groupedByCourse, setGroupedByCourse] = useState(remapCourses(labResults));
    const [downloading, setDownloading] = useState({});
    const [lodsRedirectStatus, setLodsRedirectStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [lodsError, setLodsError] = useState(false);
    const [pyWarsProvisionedPassword, setPyWarsProvisionedPassword] = useState('');
    const [pyWarsResetPassword, setPyWarsResetPassword] = useState('');
    // const [pyWarsError, setPyWarsError] = useState(false);
    const [loadingPywarsPassword, setLoadingPywarsPassword] = useState(false);
    const [pywarsAccountExists, setPywarsAccountExists] = useState(false);
    const [pyWarsProvisionError, setPyWarsProvisionError] = useState(false);
    const [pyWarsResetError, setPyWarsResetError] = useState(false);
    const [loadingPywarsAccounts, setLoadingPyWarsAccounts] = useState(false);

    useState(() => {
        const currentResults = labResults;

        if (currentResults && labResults !== currentResults) {
            setGroupedByCourse(remapCourses(currentResults));
        }
    }, [labResults, accountReducer, filesApiFeatureFlag]);

    /**
     * Toggle the flag to show/hide the alert
     */
    const toggleAlert = () => {
        setShowAlert(!showAlert);
    };

    /**
     * handle click of download ovpn. Kick off download unless error -then show alert
     * @param {object} event
     */
    const downloadOVPN = ({ target: { dataset: { os, labId, userProductCacheId }}}) => {
        setDownloading(R.assoc(labId + '-' + os, true, downloading));

        getOVPNCert(labId, os, userProductCacheId)
            .then(({ filename, data }) => {
                const blob = new Blob([data], { type: 'text/plain' });

                setDownloading(R.dissoc(labId + '-' + os, downloading));
                textToFile(blob, filename);
            })
            .catch(() => {
                toggleAlert();
                setDownloading(R.dissoc(labId + '-' + os, downloading));
            });
    };

    /**
     * Render the OVPN buttons for each OS
     * @param {object} lab
     * @return {object}
     */

    const renderOVPN = ({
        id,
        user_product_cache_id,
        utc_lab_start,
        ovpn_windows,
        ovpn_linux,
        lab_2_start_exception,
        section,
    }) => {
        const validOS = R.concat(
            ovpn_windows ? ['Windows'] : [],
            ovpn_linux ? ['Mac', 'Linux'] : []
        );

        if (R.isEmpty(validOS)) {
            return null;
        }

        let labStart = utc_lab_start;

        if (section === '6' && lab_2_start_exception) {
            labStart = lab_2_start_exception;
        }

        return (<div className={ 'ovpn-downloads' + (dateBeforeNow(labStart) ? '' : ' disabled') }>
            <span className="available">
                Download OVPN Key for:
            </span>
            <span className="unavailable">
                <b>OVPN files will be available {moment.utc(labStart).from()}:</b>
            </span>
            <ul>
                {R.map((os) => (
                    <li key={ os }>
                        {downloading[id + '-' + os] ? (
                            <Downloading/>
                        ) : (
                            <div
                                className={ `os-${os}` }
                                onClick={ dateBeforeNow(labStart) ? downloadOVPN : null }
                                data-lab-id={ id }
                                data-user-product-cache-id={ user_product_cache_id }
                                data-lab-name={ nname }
                                data-os={ os }
                                alt={ os }
                            >
                            </div>
                        )}
                    </li>
                ), validOS)}
            </ul>
        </div>);
    };

    /**
     * generate the password for LODS_Cloud lab types
     * @return {string}
     */
    const generateLodsCloudAuthRedirect = () => {
        setLoading(true);

        const lodsURL = appConfig.services.skillable.baseUrl;

        return fetch(lodsURL, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((response) => {
            setLodsRedirectStatus(response.url);
        }).catch(() => {
            setLodsRedirectStatus('something went wrong');
            setLodsError(true);
        }).finally(() => {
            setLoading(false);
        });
    };

    const provisionPyWarsAccounts = () => {
        return fetch('https://www.sans.org/services/pywars/accountcreate', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((response) => {
            setPyWarsProvisionedPassword(response.password ? response.password : 'something went wrong');
            setPyWarsProvisionError(response.error_status === 2);
            setPywarsAccountExists(response.error_status === 2);
        }).catch(() => {
            setPyWarsProvisionedPassword('something went wrong');
            setPyWarsProvisionError(true);
        }).finally(() => {
            setLoadingPyWarsAccounts(false);
        });
    };

    const resetPyWarsPassword = () => {
        return fetch('https://www.sans.org/services/pywars/passwordreset', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            return response.json();
        }).then((response) => {
            setPyWarsResetPassword(response.password);
            setPyWarsResetError(false);
            setPyWarsProvisionedPassword('');
        }).catch(() => {
            setPyWarsResetPassword('something went wrong');
            setPyWarsResetError(true);
        }).finally(() => {
            setLoadingPywarsPassword(false);
        });
    };

    /**
     * @func renderInstructionSet
     * @description returns an array of formatted instructions
     * @param  {array} instructions array of instruction objects
     * @return {array}              description
     */
    const renderInstructionSet = (instructions) => {
        //Don't display expandable if lab doesn't have instructions other than overview
        return !instructions ? null : (
            instructions.map((i, index) => {
                const { field_lab_instruction_display_na, field_lab_instruction, title } = i;

                return (
                    <div key={ `${title}-${index}` }>
                        <h5>{field_lab_instruction_display_na}</h5>
                        <div dangerouslySetInnerHTML={ { __html: field_lab_instruction } }/>
                    </div>
                );
            })
        );
    };

    const renderLodsButton = () => {
        return (
            <Button
                className='generateButton'
                variant="outline-primary"
                onClick={ generateLodsCloudAuthRedirect }
            >{ loading ?
                    <Spinner animation="border" role="status" size="sm">
                        <span className="sr-only">Loading...</span>
                    </Spinner> : 'LODS Sign On' }
            </Button>
        );
    };

    /**
     * @func renderLodsMessage
     * @description returns the generated password or an error message
     * @return {object} an html object to represent a password or error message
     */
    const redirectLodsSso = () => {
        if (lodsError) {
            return <Alert variant="danger">
                <p>
                    Something went wrong. Please log out and log back in and try again.
                    If this issue persists, please contact{' '}
                    <a href="mailto:mylabs-support@sans.org">mylabs-support@sans.org</a>.
                </p>
            </Alert>;
        }
        window.open(lodsRedirectStatus, '_blank');
        setLodsRedirectStatus('');
        return renderLodsButton();
    };

    /**
     * @func renderLodsCloudInstructionSet
     * @description returns formatted instructions for a lods cloud lab, only used in the overview instructions
     * @param  {array} instructions array of instruction objects
     * @return {array}              description
     */
    const renderLodsCloudInstructionSet = (instructions) => {
        let count = 0;

        return !instructions ? null : (
            R.map(({ field_lab_instruction_display_na, field_lab_instruction }) => (
                <div className='lodsLab' key={ count++ }>
                    <h5>{ field_lab_instruction_display_na }</h5>
                    <div dangerouslySetInnerHTML={ { __html: field_lab_instruction } }/>
                    { lodsRedirectStatus !== '' ? (
                        redirectLodsSso()
                    ) : (
                        renderLodsButton()
                    )}
                </div>
            ), instructions)
        );
    };

    const renderPyWarsProvisionMessage = () => {
        if (pyWarsProvisionError) {
            if (pywarsAccountExists) {
                return <Alert variant="danger">
                    <p>
                        Your Account Has Already Created. Use the &lsquo;Reset PyWars Password&rsquo;
                        button to reset your password if forgotten
                    </p>
                </Alert>;
            }
            return <Alert variant="danger">
                <p>
                    Something went wrong. Please log out and log back in and try again.
                    If this issue persists please contact
                    <a href="mailto:mylabs-support@sans.org">mylabs-support@sans.org</a>.
                </p>
            </Alert>;
        }

        return <p><span>Password: </span>{pyWarsProvisionedPassword }</p>;
    };

    const renderPyWarsResetMessage = () => {
        if (pyWarsResetError) {
            return <Alert variant="danger">
                <p>
                    Something went wrong. Please log out and log back in and try again.
                    If this issue persists please contact
                    <a href="mailto:mylabs-support@sans.org">mylabs-support@sans.org</a>.
                </p>
            </Alert>;
        }

        return <p><span>Password: </span>{ pyWarsResetPassword }</p>;
    };

    const renderPyWarsInstructionSet = (instructions) => {
        let count = 0;

        return !instructions ? null : (
            R.map(({ field_lab_instruction_display_na, field_lab_instruction }) => (
                <div className='pyWarsLab' key={ count++ }>
                    <h5>{field_lab_instruction_display_na}</h5>
                    <div dangerouslySetInnerHTML={ { __html: field_lab_instruction } }/>
                    <p><span>Class Username:</span> {sansEmail || ''}</p>
                    <p><span>CTF Username:</span> { sansEmail + '.ctf' || '' }</p>
                    {pyWarsProvisionedPassword !== '' ? (
                        renderPyWarsProvisionMessage()
                    ) : (
                        <Button
                            className='generateButton'
                            variant="outline-primary"
                            onClick={ provisionPyWarsAccounts }>
                            {loadingPywarsAccounts ? <Spinner animation="border" role="status" size="sm">
                                <span className="sr-only">Loading...</span>
                            </Spinner> : 'Provision PyWars Account'}
                        </Button>
                    )}
                    {pyWarsResetPassword !== '' ? (
                        renderPyWarsResetMessage()
                    ) : (
                        <Button
                            className='generateButton' variant="outline-primary" onClick={ resetPyWarsPassword }>
                            {loadingPywarsPassword ? <Spinner animation="border" role="status" size="sm">
                                <span className="sr-only">Loading...</span>
                            </Spinner> : 'Reset PyWars Password'}
                        </Button>
                    )}
                </div>
            ), instructions)
        );
    };

    const instructionSetChecker = (specialClass, Overview) => {
        if (specialClass === 'LODS_CLOUD') {
            return renderLodsCloudInstructionSet(Overview);
        } else if (specialClass === 'PYWARS') {
            return renderPyWarsInstructionSet(Overview);
        }
        return renderInstructionSet(Overview);
    };

    /**
     * Renders lab licensing information
     * @param {Array} labLicensing
     * @param {Lab} lab
     */
    const renderLabLicensing = (labLicensing, lab) => {
        const labData = extractLabData(lab);

        return labLicensing.filter(
            (licenseInstruction) => !licensingExclude.includes(
                licenseInstruction.field_lab_instruction_display_na)
        ).map((licensingInstructions, index) => {
            // We override the tab name here if necessary
            const title = licensingInstructions.field_lab_instruction_display_na === 'Cloud Training Environment' ? 'Cloud Training Environment' : undefined;

            return <LicenseExpandable
                key={ `${licensingInstructions.field_lab_id}-${index}` }
                licenseInstructions={ licensingInstructions }
                labData={ labData }
                title={ title }
            />;
        });
    };

    /**
     * @func renderLab
     * @description returns an html object to represent a lab
     * @param  {import('../../../../types/types').LabProduct} lab lab containing lab info and an array of instructions
     * @return {object} an html object to represent a lab
     */
    const renderLab = (lab, index) => {
        const instructions = R.omit(['Overview', 'Licensing', 'AMI'], lab.instructions);
        const lab_overview = lab.instructions.Overview;
        const lab_licensing = lab.instructions.Licensing;
        const ami = lab.instructions.AMI;

        let lab_name = lab.name.replace('GEN', 'OnDemand');

        if (!lab_name.includes('OnDemand')) {
            if (typeof lab.event_name !== 'undefined' &&
                lab.event_name !== '' &&
                lab.event_name !== null
            ) {
                lab_name += ` (${lab.event_name})`;
            } else {
                lab_name += ` (${lab.lab_start})`;
            }
        }

        const labOverview = instructionSetChecker(lab.type, lab_overview);

        const labAMI = (ami) ?
            <LicenseExpandable
                lab={ lab }
                licenseInstructions={ ami[0] }
                title="Amazon Machine Images"
            /> : null;

        const labInstructionValues = Object.values(instructions);

        const rangeRules = rangeProvisioningReducer.courses;
        const rangeInstances = rangeProvisioningReducer.ranges;

        // Try to find a specific rule for this student...
        const { applicableRule, studentRanges, activeStudentRange } = renderRangeInstantiationButtonInputs(
            lab,
            rangeRules,
            rangeInstances,
            sansAccountID
        );

        // Determine which files match the course
        // Standard (non DRP) files should match lab name + EPID (or 'null', in the case of employees)
        const fileIdentifier = lab.name + `-${lab.event_product_id}`;

        let courseFilesWithEPID = studentFiles[fileIdentifier];

        if (courseFilesWithEPID === undefined) {
            // Some labs have underscores in their names, but the files are stored with hyphens
            // If the above lookup failed, try again with underscores replaced with hyphens
            courseFilesWithEPID = studentFiles[fileIdentifier.replace('_', '-')];
        }

        // DRP generated files have no concept of EPID and are identified by lab name only
        let courseFilesWithoutEPID = studentFiles[lab.name];

        if (courseFilesWithoutEPID === undefined) {
            courseFilesWithoutEPID = studentFiles[lab.name.replace('_', '-')];
        }

        let courseFiles;

        // If there are results for files with and w/o identifier, merge them into courseFiles
        // Otherwise, set courseFiles as the existing one
        if (courseFilesWithEPID && courseFilesWithoutEPID) {
            courseFiles = courseFilesWithEPID.metadata.concat(courseFilesWithoutEPID.metadata)
        } else {
            courseFiles = courseFilesWithEPID ?? courseFilesWithoutEPID;
        }

        return (
            <Expandable
                key={ `${lab.id}-${index}` }
                title={ lab_name }
                subTitle={ renderOVPN(lab) }
                colorScheme="darkBlue"
            >
                {labOverview}
                {applicableRule && <RangeInstantiationComponent
                    rule={ applicableRule }
                    ranges={ studentRanges }
                    activeRange={ activeStudentRange }
                />}
                {lab_licensing && renderLabLicensing(lab_licensing, lab) }

                {
                    // eslint-disable-next-line no-shadow
                    labInstructionValues.map((inst, index) => {
                        const { field_lab_id, field_instruction_type, field_lab_instruction_display_na } = inst[0];

                        return (

                            <Expandable
                                key={ `${field_lab_id}-${index}-${field_lab_instruction_display_na}` }
                                title={ field_instruction_type }
                                colorScheme="darkGrey"
                            >

                                {renderInstructionSet(inst)}
                            </Expandable>
                        );
                    }
                    )
                }
                {labAMI}

                {
                    // eslint-disable-next-line no-nested-ternary
                    filesApiFeatureFlag === 'v1' ?
                        renderDynamicContentV1(lab, null, activeStudentRange, sansAccountID) :
                        filesApiFeatureFlag === 'v2' && courseFiles ?
                            renderDynamicContentV2(courseFiles, sansAccountID) :
                            null
                }
            </Expandable>
        );
    };

    /**
     * @func renderCourse
     * @description returns an html object to represent a course
     * @param  {array} records records of the courses
     * @return {object} an html object to represent a course
     */
    const renderCourse = (records) => {
        // NOTE: We only pull the first lab of the course.
        // I don't know why it doesn't particularly make sense @JedaiAtSans
        const lab = records[0];
        const {
            product_id,
            course_name,
            instructions,
        } = lab;

        if (lab.mockedLab) {
            return (
                <FeatureHide key={ product_id } loggedInUserEmail={ sansEmail }>
                    <Expandable
                        key={ product_id }
                        title={ course_name || 'Unnamed course' }
                        defaultExpanded={ R.length(R.keys(groupedByCourse)) < 3 }
                    >
                        { !hasInstructions(instructions) ?
                            <NoLabInstructions /> : records.map(renderLab)
                        }
                    </Expandable>
                </FeatureHide>
            );
        }

        const sortedLabs = records.sort(sortLabs);

        return (
            <Expandable
                key={ product_id }
                title={ course_name || 'Unnamed course' }
                defaultExpanded={ R.length(R.keys(groupedByCourse)) < 3 }
            >
                { !hasInstructions(instructions) ?
                    <NoLabInstructions /> : sortedLabs.sort(compareODAndLiveLab).map(renderLab)
                }
            </Expandable>
        );
    };

    // Set up any mocked courses for "Moclabking and You"...
    if (process.env.NODE_ENV !== 'test') {
        groupedByCourse.mock = mockedCourses;
    }

    return (
        <div className="lab-display">
            {showAlert && (
                <Alert
                    className="labAlert"
                    variant="danger"
                    onClose={ toggleAlert }>
                    <p>
                        OVPN generation not currently available for this
                        lab.
                    </p>
                    <p>
                        Please contact <a href="mailto:Virtual-Labs-Support@sans.org">
                            Virtual-Labs-Support@sans.org
                        </a>
                    </p>
                </Alert>
            )}
            <div className="lab-text">
                Your SANS courses listed below require software/service
                licenses and/or access to range environments to complete the
                accompanying labs. For each course, you will find specific
                instructions pertaining to the relevant components of that
                course. Some courses may have multiple components. If you
                are registered for both a Live and an OnDemand version of
                the same course, you will see both listed below along with
                their respective MyLabs components. It is important to
                carefully review the “Setup Instructions” document for your
                course, available with the rest of your Course Material
                Downloads. This includes completing “Lab 0” and any
                applicable troubleshooting documents prior to the start of
                your class. The Setup Instructions will detail how to use
                the information and files presented below. If you need
                assistance, please contact support
                at <a href="mailto:mylabs-support@sans.org">mylabs-support@sans.org</a>.
            </div>
            {R.isEmpty(groupedByCourse) ? (
                <div className="no-labs-content">
                    <p>
                        There are no virtual labs associated with your
                        account. If you believe this is an error please
                        contact
                        <a href="mailto:Virtual-Labs-Support@sans.org">
                            Virtual-Labs-Support@sans.org
                        </a>
                        for assistance.
                    </p>
                    <p>
                        {' '}
                        If you have recently registered for an OnDemand
                        course but haven’t started it please return to{' '}
                        <a href="https://ondemand.sans.org">
                            ondemand.sans.org
                        </a>{' '}
                        and begin the course.{' '}
                    </p>
                </div>
            ) : (
                Object.values(groupedByCourse)
                    .sort(sortCourses)
                    .map(renderCourse)
            )}

            {!R.isNil(sansEmail) && (
                <ContactUs
                    name={ nname }
                    sansAccountID={ sansAccountID }
                    sansEmail={ sansEmail }
                />
            )}
        </div>
    );
};

LabDisplay.propTypes = {
    labResults: PropTypes.array.isRequired,
};

export default LabDisplay;
