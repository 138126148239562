import React from 'react';
import App from '../../views/app/app';
import { Provider } from 'react-redux';
import { setupStore } from '../../app/store';
import { TestLab, formatInstructionsAsRaw } from '../../mocks/labs/data';
import { rest } from 'msw';
import { TestFileMetadata } from '../../mocks/files/data';
import { 
    MockedState,
    MockAccountResponseData as accountResponseData,
} from '../../views/app/app.stories';
import { generatedLabMocks } from '../../mocks/labs/courses/generateLabMocks';

const { sec560: sec560Mocks } = generatedLabMocks;
const usersResponseDataBase = {
    rangeProvisioning: {
        courses: {
            data: [],
            meta: {},
        },
        ranges: {
            data: [],
            meta: {},
        },

    },
    result: 'success',
};

const usersResponseDataLive = {
    ...usersResponseDataBase,
    data: [
        formatInstructionsAsRaw(sec560Mocks.live[80755].a),
        formatInstructionsAsRaw(sec560Mocks.live[80755].b),
    ],
};

const usersResponseDataOnDemand = {
    ...usersResponseDataBase,
    data: [
        formatInstructionsAsRaw(sec560Mocks.onDemand.a),
        formatInstructionsAsRaw(sec560Mocks.onDemand.b),
    ],
};

export default {
    title: 'Mocks/Courses/SEC560',
    component: App,
    decorators: [(Story) =>
        <Provider store={ setupStore(MockedState) } >
            <Story />
        </Provider>,
    ],
    excludeStories: ['MockedState', 'sec560V1FilesResponse', 'sec560V2FilesResponse'],
    parameters: {
        controls: { exclude: [] },
    },
};

export const sec560V1FilesResponse = {
    [80755]: [
        TestFileMetadata(sec560Mocks.live[80755].a, {
            fileName: 'sec560-lab-range',
        }),
        TestFileMetadata(
            sec560Mocks.live[80755].a,
            {
                fileName: 'sec560-ctf-range',
                contentType: 'ovpn',
            }
        ),
    ],
};
// Set MSW handlers for the mock
export const FilesApiV1Live = {
    parameters: {
        msw: {
            handlers: [
                rest.get('http://localhost:8200/api/account_details', (req, res, ctx) => {
                    return res(
                        ctx.json(accountResponseData)
                    );
                }),
                rest.get('http://localhost:8200/api/users', (req, res, ctx) => {
                    return res(
                        ctx.json(usersResponseDataLive)
                    );
                }),
                rest.post('http://localhost:8200/api/files', (req, res, ctx) => {
                    if (req.url.searchParams.get('course') === 'SEC560') {
                        return res(
                            ctx.json(sec560V1FilesResponse)
                        );
                    }

                    return res(
                        ctx.json([])
                    );
                }),
            ],
        },
    },
};

export const sec560V2FilesResponse = (payload) => {
    const { eventProductID, instance, labID, labName } = payload;
    const { name: instanceA } = sec560Mocks.live[80755].a;
    const { name: instanceB } = sec560Mocks.live[80755].b;

    return ({
        SEC560: {
            [instanceA]: {
                labName,
                eventProductID,
                labID,
                instance,
                metadata: [
                    TestFileMetadata(sec560Mocks.live[80755].a, {
                        fileName: 'sec560-lab-range',
                        contentType: 'ovpn',
                    }),
                ],
            },
            [instanceB]: {

                labName: instanceB,
                eventProductID,
                labID,
                instance,
                metadata: [
                    TestFileMetadata(
                        sec560Mocks.live[80755].b,
                        {
                            fileName: 'sec560-ctf-range',
                            contentType: 'ovpn',
                        }
                    ),
                ],
            },
        },
    })
}

export const FilesApiV2Live = {
    decorators: [(Story) =>
        <Provider store={ setupStore({
            configReducer: {
                config: {
                    ...MockedState.configReducer.config,
                    features: {
                        filesApiProxy: {
                            getStudentFiles: {
                                version: 2,
                            },
                        },
                    },
                },
            },
        }) } >
            <Story />
        </Provider>,
    ],
    parameters: {
        msw: {
            handlers: [
                rest.get('http://localhost:8200/api/account_details', (req, res, ctx) => {
                    return res(
                        ctx.json(accountResponseData)
                    );
                }),
                rest.get('http://localhost:8200/api/users', (req, res, ctx) => {
                    return res(
                        ctx.json(usersResponseDataLive)
                    );
                }),
                rest.post('http://localhost:8200/api/files', async (req, res, ctx) => {
                    const reqPayload = await req.json();

                    return res(
                        ctx.json(sec560V2FilesResponse(
                            reqPayload[sec560Mocks.live[80755].a.testExtras.courseCatalog][0]
                        ))
                    );
                }),
            ],
        },
    },
};
